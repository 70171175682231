import Link from '../components/link';

import './about.scss';

function About() {
  return (
    <div id="about" className="about section-container">
      <div className="section-container-inner">
        <div className="uppercase bold">About Lily</div>
        <div className="about-info-container">
          <p>
            Lily is a software engineer with a Bachelor of Science degree from MIT.
            After graduating from MIT, she moved to San Francisco to start her career in the tech industry.
            She has been an engineer since 2015, with experiences from series A startups
            to enterprise with 10k+ employees.
            She has worked with Ruby on Rails, Postgres, MongoDB, ElasticSearch, Firebase, Python, and of course, React.
          </p>
          <p>
            Lily is a well-rounded curious individual who enjoys a good challenge and learning new things.
            She has taken a UX design bootcamp through General Academy and Machine Learning classes through Stanford.
          </p>
          <p>
            Lily is a strong believer in the power of documentation, and as such, an avid writer.
            She has been documenting and sharing her experiences and learnings - both in tech and outside of tech - since 2016.
            Her blog on <Link url="https://lilychencodes.medium.com/">Medium</Link> has over 6k followers.
          </p>
        </div>
      </div>
    </div>
  )
}

export default About;
