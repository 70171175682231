
import './coding-resume.scss';

const TIMELINE = [
  {
    years: '2022 - Present',
    company: 'Datadog',
    role: 'Senior software engineer',
    responsibilities: [
      'Responsibilities include leading the frontend development effort of the Continuous Profiler.'
    ]
  },
  {
    years: '2019 - 2022',
    company: 'Slack Technologies',
    role: 'Front-end infrastructure engineer',
    responsibilities: [
      'Worked on many projects whose goal is to understand performance bottlenecks and to improve front-end performance.'
    ]
  },
  {
    years: '2017 - 2019',
    company: 'Apollo.io',
    role: 'Software engineer',
    responsibilities: [
      "Built many of Apollo's sales features (e.g. frontend of an analytics engine used for insights, a rules engine for automating tasks, and a scoring engine for assigning weights to contacts' and accounts' features)."
    ]
  },
  {
    years: '2016 - 2017',
    company: 'Pillow',
    role: 'Software engineer',
    responsibilities: [
      'Identified and solved technical issues across the web stack. For example, developed integration testing framework, optimized the loading speed of the homepage, automated handling of support emails.',
      'Led the development of many core product features in both web and React-Native mobile app.'
    ]
  },
  {
    years: '2015 - 2016',
    company: 'Lumos Labs',
    role: 'Front-end developer',
    responsibilities: [
      'Automated the manual process of email UI testing (with the Litmus platform).',
      'Led the very first conversion of a frequently visited static HTML page to React for faster rendering and better user experience.',
      'Implemented and analyzed A/B tests for homepage, marketing emails, and paywalls.'
    ]
  }
]

function CodingResume() {
  return (
    <div id="coding-resume" className="coding-resume section-container">
      <div className="section-container-inner">
        <div className="uppercase bold">Coding & Resume</div>
        <div className="resume-items">
          {TIMELINE.map((experience) => {
            const { years, company, role, responsibilities } = experience;

            return (
              <div className="resume-item-container" key={company}>
                <div className="resume-item-outerbox resume-item-years-company">
                  <div className="resume-item-years resume-item-left bold">{years}</div>
                  <div className="circle-border" />
                  <div className="resume-item-company resume-item-right bold">{company}</div>
                </div>
                <div className="resume-item-outerbox">
                  <div className="resume-item-left" />
                  <div className="resume-item-role-responsibilities resume-item-right">
                    <div className="resume-item-role">{role}</div>
                    <div className="resume-item-responsibilities">
                      {responsibilities.map((resp, idx) => (
                        <p key={idx} className="resume-item-responsibility">{resp}</p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default CodingResume;
