import { SocialIcon } from 'react-social-icons';

import Link from '../components/link';

import './contact.scss';

function Contact() {
  const apps = [
    { name: 'LinkedIn', network: 'linkedin', url: 'https://www.linkedin.com/in/yilingchen405/' },
    { name: 'Medium', network: 'medium', url: 'https://lilychencodes.medium.com/' },
    { name: 'Instagram', network: 'instagram', url: 'https://www.instagram.com/bendylilyogi/' },
    { name: 'Facebook', network: 'facebook', url: 'https://www.facebook.com/lilychencodes/' },
    { name: 'Twitter', network: 'twitter', url: 'https://twitter.com/lilychencodes/' }
  ];
  return (
    <div id="contact" className="contact section-container">
      <div className="section-container-inner">
        <div className="uppercase bold">Contact</div>
        <div>
          <p className="semibold">
            Find Lily on social media:
          </p>
          <div className="social-items">
            {apps.map((app) => ((
              <div key={app.network} className="social-item">
                <SocialIcon className="social-media-icon" network={app.network} url={app.url} />
                <Link url={app.url}>{app.name}</Link>
              </div>
            )))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact;
