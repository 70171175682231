import './link.scss';

function Link(props) {
  const { url, children } = props;

  return (
    <a className="link" href={url}>{children}</a>
  )
}

export default Link;
