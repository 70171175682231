import Portfolio from './portfolio';

import './App.scss';

function App() {
  return (
    <Portfolio />
  );
}

export default App;
