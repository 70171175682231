import React, { useState } from 'react';

import cms from '../img/medium/cms.png';
import planning from '../img/medium/planning.png';
import sonic from '../img/medium/sonic.png';
import effectiveEngineer from '../img/medium/effective-engineer.jpeg';
import abTest from '../img/medium/ab-test.png';
import faq from '../img/medium/faq.png';
import linearReg from '../img/medium/linear-reg.png';
import probLinearReg from '../img/medium/prob-linear-reg.png';
import glm from '../img/medium/glm.png';
import tuning from '../img/medium/tuning.png';
import analogy from '../img/medium/analogy.png';
import spamFilter from '../img/medium/spam-filter.png';
import feelingBlue from '../img/medium/feeling-blue.png';
import feelingFree from '../img/medium/feeling-free.jpeg';
import create from '../img/medium/create.png';
import coding from '../img/medium/coding.jpeg';
import asianAmerican from '../img/medium/asian-american.png';
import runner from '../img/medium/runner.jpeg';

import './writing.scss';

const IMAGES = {
  'cms': cms,
  'planning': planning,
  'sonic': sonic,
  'effective-engineer': effectiveEngineer,
  'ab-test': abTest,
  'faq': faq,
  'linear-reg': linearReg,
  'prob-linear-reg': probLinearReg,
  'glm': glm,
  'tuning': tuning,
  'analogy': analogy,
  'spam-filter': spamFilter,
  'feeling-blue': feelingBlue,
  'feeling-free': feelingFree,
  'create': create,
  'coding': coding,
  'asian-american': asianAmerican,
  'runner': runner,
}


const BLOG_SECTIONS = {
  software_development: {
    name: 'Software Development',
    description: 'Featured blogs from the last 7 years in tech.',
    articles: [
      {
        image: 'cms',
        title: 'How I built a Content Management System for a React app in one day',
        url: 'https://medium.com/hackernoon/how-i-built-a-content-management-system-for-a-react-app-in-one-day-269df17f5509',
        description: 'A React/Firebase CMS to manage web content and languages.'
      },
      {
        image: 'planning',
        title: "The most common problem I've seen in product/engineering process",
        url: 'https://medium.com/hackernoon/the-most-common-problem-ive-seen-in-product-engineering-process-debd821a423f',
        description: 'The XY problem: asking about an attempted solution rather than the real problem.'
      },
      {
        image: 'sonic',
        title: '8 quick ways of improving React app performance',
        url: 'https://blog.devgenius.io/8-quick-ways-of-improving-react-app-performance-4eb187608c4',
        description: 'How to reduce CPU and memory consumption.'
      },
      {
        image: 'effective-engineer',
        title: 'What I do to be more effective as a software engineer',
        url: 'https://lilychencodes.medium.com/what-i-do-to-be-more-effective-as-a-software-engineer-87d2c644bfb6',
        description: 'Lessons from "The Effective Engineer" by Edmond Lau.'
      },
      {
        image: 'ab-test',
        title: 'How to quickly set up A/B testing for React websites',
        url: 'https://lilychencodes.medium.com/how-to-quickly-set-up-a-b-testing-for-react-websites-dcb321fcd1f',
        description: 'Setting up a proof-of-concept A/B test to start collecting data in as little as 30 minutes.'
      },
      {
        image: 'faq',
        title: 'How a common interview question from hiring managers reveals a lot about current career stage',
        url: 'https://lilychencodes.medium.com/how-a-common-interview-question-from-hiring-managers-reveals-a-lot-about-current-career-stage-3ed303f504fe',
        description: 'Different depths of answers to a common engineer interview question.'
      },
    ]
  },
  machine_learning: {
    name: 'Machine Learning',
    description: 'Machine learning articles based on class materials from Stanford.',
    articles: [
      {
        image: 'linear-reg',
        title: 'Linear regression and gradient descent for absolute beginners',
        url: 'https://towardsdatascience.com/linear-regression-and-gradient-descent-for-absolute-beginners-eef9574eadb0',
        description: 'A first of a series of machine learning articles written for engineers without any machine learning background.'
      },
      {
        image: 'prob-linear-reg',
        title: 'Probabilistic interpretation of linear regression clearly explained',
        url: 'https://towardsdatascience.com/probabilistic-interpretation-of-linear-regression-clearly-explained-d3b9ba26823b',
        description: 'Why the least squares method is used in linear regression.'
      },
      {
        image: 'glm',
        title: 'Generalized Linear Models clearly explained',
        url: 'https://towardsdatascience.com/generalized-linear-models-clearly-explained-5c51993e41a4',
        description: 'What makes a GML GML?'
      },
      {
        image: 'analogy',
        title: 'Intro to natural language processing — how to encode meaning of a word',
        url: 'https://towardsdatascience.com/intro-to-natural-language-processing-how-to-encode-meaning-of-a-word-b1742d4beca2',
        description: 'The math and implementation of Word2Vec, a model that encodes words into vectors.'
      },
      {
        image: 'tuning',
        title: "A beginner's guide to understanding and performing hyperparameter tuning for Machine Learning models",
        url: 'https://towardsdatascience.com/the-what-why-and-how-of-hyperparameter-tuning-for-machine-learning-models-1a2634e9ca9e',
        description: 'The What, Why, and How of Hyperparameter Tuning.'
      },
      {
        image: 'spam-filter',
        title: 'Logic and implementation of a spam filter machine learning algorithm',
        url: 'https://towardsdatascience.com/logic-and-implementation-of-a-spam-filter-machine-learning-algorithm-a508fb9547bd',
        description: 'How the Naive Bayes probability theorem is used for spam classification.'
      }
    ]
  },
  life_lessons: {
    name: 'Life Lessons',
    description: 'Inspirational stories about overcoming challenges.',
    articles: [
      {
        image: 'feeling-blue',
        title: 'The biggest disappointment of my life',
        url: 'https://byrslf.co/the-biggest-disappointment-of-my-life-fe182b5040e1',
        description: 'From pre-med to software engineer: how Lily rebuilt her life after plan A failed.'
      },
      {
        image: 'coding',
        title: 'On overcoming imposter syndrome in the tech industry',
        url: 'https://startupsventurecapital.com/on-overcoming-imposter-syndrome-in-the-tech-industry-6840ce5b022d',
        description: "Lily's battle with the imposter syndrome in the first couple years in tech."
      },
      {
        image: 'feeling-free',
        title: 'How it feels to leave the comfort zone and put myself out there',
        url: 'https://byrslf.co/how-it-feels-to-leave-the-comfort-zone-and-put-myself-out-there-624f0fce7588',
        description: 'The fulfillment that comes with taking risks and self-expression.'
      },
      {
        image: 'asian-american',
        title: 'Why self-acceptance was hard growing up as an Asian-American',
        url: 'https://byrslf.co/why-self-acceptance-was-hard-growing-up-as-an-asian-american-43aa09414ecd',
        description: 'A battle of two cultures, and the internal conflicts they bring.'
      },
      {
        image: 'runner',
        title: 'Why I punch above my weight',
        url: 'https://byrslf.co/i-love-losing-said-nobody-ever-5295bb45c2a',
        description: 'How tackling big challenges lead to faster growth.'
      },
      {
        image: 'create',
        title: 'On designing your way to a better future',
        url: 'https://medium.com/hackernoon/on-designing-your-way-to-a-better-future-a9e0c3e4e151',
        description: 'A new way of viewing life as an iterative process of prototypes and experimentation.'
      },
    ]
  }
};

function Writing() {
  const [hoveredSection, setHoveredSection] = useState(null);
  const [activeSection, setActiveSection] = useState('software_development');
  const [hoveredArticle, setHoveredArticle] = useState(null);

  return (
    <div id="writing" className="writing section-container">
      <div className="section-container-inner">
        <div className="uppercase bold writing-section-header">Writing</div>
        <div className="writing-sections">
          {Object.keys(BLOG_SECTIONS).map((sectionKey) => {
            const { name, description } = BLOG_SECTIONS[sectionKey];
            const isActive = activeSection === sectionKey;
            const isHovered = hoveredSection === sectionKey;
            return (
              <div
                key={sectionKey}
                onClick={() => setActiveSection(sectionKey)}
                onMouseEnter={() => setHoveredSection(sectionKey)}
                onMouseLeave={() => setHoveredSection(null)}
                className={`writing-section ${isActive && 'writing-section-active'} ${isHovered && 'writing-section-hovered'}`}>
                <div className="writing-text">
                  <div className="uppercase writing-section-title">{name}</div>
                  <p className="writing-section-description">{description}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="writing-articles">
        {activeSection && BLOG_SECTIONS[activeSection].articles.map((article) => {
          const { title, description, url, image } = article;
          const isArticleHovered = hoveredArticle === title;
          const articleImg = IMAGES[image];
          return (
            <div
              key={title}
              className={`writing-article ${isArticleHovered && 'writing-article-hovered'}`}
              onMouseEnter={() => setHoveredArticle(title)}
              onMouseLeave={() => setHoveredArticle(null)}>
              <a className="writing-article-link" href={url} target="_blank">
                <div className="article-image" style={{ backgroundImage: `url(${articleImg})` }} />
                <div className="article-text">
                  <p>{title}</p>
                  <p className="article-description">{description}</p>
                </div>
              </a>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Writing;
