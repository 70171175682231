import React from 'react';

import Header from './sections/header';
import About from './sections/about';
import CodingResume from './sections/coding-resume';
import Writing from './sections/writing';
import YogaDance from './sections/yoga-dance';
import Contact from './sections/contact';
import Footer from './sections/footer';

import './portfolio.scss';

export default class Portfolio extends React.Component {
  render() {
    return (
      <div id="portfolio">
        <Header />
        <About />
        <CodingResume />
        <Writing />
        <YogaDance />
        <Contact />
        <Footer />
      </div>
    )
  }
}
