import React, { useState } from 'react';

import { SocialIcon } from 'react-social-icons';

import './footer.scss';

function Footer() {
  const [hoveredIcon, setHoveredIcon] = useState(null);

  const apps = [
    { network: 'linkedin', url: 'https://www.linkedin.com/in/yilingchen405/' },
    { network: 'medium', url: 'https://lilychencodes.medium.com/' },
    { network: 'instagram', url: 'https://www.instagram.com/bendylilyogi/' },
  ];

  return (
    <footer className="footer">
      <div className="footer-social-icons">
        {apps.map((app) => {
          const { url, network } = app;
          const shouldShowDarkBg = hoveredIcon && hoveredIcon !== network;
          return (
            <span
              key={network}
              onMouseEnter={() => setHoveredIcon(network)}
              onMouseLeave={() => setHoveredIcon(null)}>
              <SocialIcon
                bgColor={shouldShowDarkBg ? '#a6a6a6' : '#ffffff'}
                fgColor="#171717"
                className="footer-social-icon"
                network={network}
                url={url} />
            </span>
          )
        })}
      </div>
      <p className="footer-quote">
        "Being unique is better than being perfect."
      </p>
    </footer>
  )
}

export default Footer;
