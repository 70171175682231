import React, { useState } from 'react';

import externalLink from '../icons/external-link.svg';
import silver from '../icons/silver.png';
import gold from '../icons/gold.png';

import ytt from '../img/ytt.png';
import nationals from '../img/nationals.jpeg';
import goldenGate from '../img/goldenGate.png';
import northwest from '../img/northwest.jpeg';

import './yoga-dance.scss';

const IMAGES = {
  nationals,
  goldenGate,
  northwest,
}

const MEDALS = {
  silver,
  gold,
}

const COMPETITIONS = [
  {
    placement: 'silver',
    url: 'https://www.youtube.com/watch?v=u7DlJlhnp8o',
    title: 'San Francisco, Feb 2019',
    image: 'goldenGate',
  },
  {
    placement: 'gold',
    url: 'https://www.youtube.com/watch?v=Do2LiPmWHak',
    title: 'San Francisco, Aug 2019',
    image: 'nationals',
  },
  {
    placement: 'silver',
    url: 'https://www.youtube.com/watch?v=6gRaZ6znJNs',
    title: 'Seattle, Nov 2017',
    image: 'northwest',
  }
];

function YogaDance() {
  const [hoveredCompetition, setHoveredCompetition] = useState(null);

  return (
    <div id="yoga-dance" className="yoga-dance">
      <div className="yoga-dance-inner">
        <div className="uppercase bold yoga-dance-title">Yoga & Dance</div>
        <div className="section-yoga">
          <div className="yoga-info">
            <div className="yoga-info-inner">
              <p>Lily is a certified yoga instructor. She completed her 200-Hour of Yoga Teacher Training at Yoga Garden SF in June 2020.</p>
              <img className="ytt-cert" src={ytt} />
            </div>
          </div>
        </div>
        <div className="section-dance">
          <div>
            <div className="pole-intro">
              Lily is a pole dancer who has won multiple awards at national competitions. Check out her first place championship routine right below!
            </div>
            <div className="pole-comp-items">
              {COMPETITIONS.map((competition) => {
                const { title, url, image, placement } = competition;
                const isHovered = hoveredCompetition === image;
                const imgUrl = IMAGES[image];
                const isNationals = image === 'nationals';
                const placementMedal = MEDALS[placement];

                return (
                  <div
                    key={image}
                    style={{ backgroundImage: `url(${imgUrl})`}}
                    className={`pole-comp-item ${isHovered && 'pole-comp-item-hovered'}`}
                    onMouseEnter={() => setHoveredCompetition(image)}
                    onMouseLeave={() => setHoveredCompetition(null)}
                    onClick={() => window.open(url, '_blank')}>
                    <img className="pole-comp-medal" src={placementMedal} />
                    <p className="pole-comp-title semibold">{title}</p>
                    {isHovered && <div className={`pole-comp-item-hovered-modal ${isNationals && 'dark-overlay'}`}>
                      <div className="pole-comp-item-watch">Watch on YouTube</div>
                      <img width="40" height="40" src={externalLink} />
                    </div>}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="section-dance-video">
            <iframe
              className="competition-video-youtube"
              src="https://www.youtube.com/embed/Do2LiPmWHak"
              title="Lily 1st Place Level 4 Championship"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
            </iframe>
          </div>
        </div>
      </div>
    </div>
  )
}

export default YogaDance;
