import './header.scss';

function Header() {
  const menuItems = [
    { name: 'About', path: '#about' },
    { name: 'Coding & Resume', path: '#coding-resume' },
    { name: 'Writing', path: '#writing' },
    { name: 'Yoga & Dance', path: '#yoga-dance' },
    { name: 'Contact', path: '#contact' },
  ];

  return (
    <div id="header" className="above-the-fold">
      <div className="top-nav-menu">
        <div className="nav-name uppercase">Lily Chen</div>
        <div className="menu-list">
          {menuItems.map((item) => {
            const { name, path } = item;
            return (
              <a
                key={name}
                href={path}
                className="menu-item uppercase">
                {name}
              </a>
            )
          })}
        </div>
      </div>
      <div className="header-img">
        <div className="intro-blurbs">
          <div className="intro-blurb-container">
            <div className="intro-blurb blurb-name uppercase semibold">Lily Chen</div>
          </div>
          <div className="intro-blurb-container">
            <div className="intro-blurb blurb-info semibold">Software Engineer, Writer, Yoga Teacher, Dancer</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
